<template>
    <div class="up_content_inner up_violation">
        <div class="up_main_container">
            <div class="up_page_header p-mb-4 p-d-flex p-jc-between">
                <h2 class="up_page_title">View Violation</h2>
                <div class="p-d-flex">
                    <Button type="button" icon="pi pi-arrow-left" label="Back" class="p-button-primary" @click="$router.push({
                        name: 'CustomerScaningReport',
                        params: {
                            uuid: violationReport.uuid
                        },
                    })" />
                </div>
            </div>
            <div v-if="violationReport.loading" class="loading">Loading...</div>
            <div v-if="!violationReport.loading" class="up_inner_container">
                <Accordion>
                    <AccordionTab v-for="report in violationReport.data" :key="report.item" >
                        <template #header>
                            <span>{{report.item}} ({{report.count}})</span>
                        </template>
                        <div class="up_violation_box" v-for="(reportInner, i) in report.affectedCode" :key="i" :header="report.item">
                            <h5 class="mb-3">Affected Code</h5>
                            <div class="up_violation_code_wrap">{{reportInner}}</div>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    </div>
</template>

<script>
import { userPortalScanedviolation } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import customerLogout from '../../../../helper/userLogout/customerLogout';
import { useRoute } from "vue-router";
import router from "../../../../router/index";
import axios from "axios";
import { useStore } from 'vuex';
import { reactive, onBeforeMount } from 'vue';

export default {
    name: 'ViolationReport',
    setup(){
        //Application store call
        const store = useStore();

        //Route call
        const route = useRoute();

        const violationReport = reactive ({
            data: null,
            uuid: null,
            loading: false
        });

        onBeforeMount(()=>{
            if (Object.keys(route.params).length > 0) {
                violationReport.uuid = route.params.uuid;
                fetchViolationReport();
            } else {
                router.push({name: "CustomerAccessibilityScan"});
            }
        });

        const fetchViolationReport = async() => {
            try{
                violationReport.loading = !violationReport.loading;
                const response = await axios.get( userPortalScanedviolation, {
                    params:{
                        uuid: violationReport.uuid
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                violationReport.data = response.data.data
                violationReport.loading = !violationReport.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        return{
            violationReport
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/component/userportal/pages/accessibilityScan/ViolationReport';
</style>